@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

.leaflet-container {
  z-index: 1;
}

.-mr-128 {
  margin-right: -32rem;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

@keyframes flash {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@layer base {
  a {
    @apply text-softblack;
  }

  a.link-underlined {
    @apply underline hover:no-underline hover:cursor-pointer;
  }

  body {
    @apply font-normal;
    @apply text-softblack;
  }

  body.search-open-mobile {
    overflow: hidden;
    position: static;
  }

  body.search-open-desktop {
    overflow: hidden;
  }
}

@layer components {
  .ah-location a {
    @apply hover:underline hover:underline-offset-2;
  }

  .image-gallery-thumbnail-border.active {
    @apply after:content-[''] after:absolute after:w-[calc(100%+8px)] after:h-[calc(100%+8px)] after:-top-1 after:-left-1 after:transition after:rounded-md after:border-2 after:border-green-70;
  }

  .ah-form-group {
    @apply mb-3;
  }

  .ah-form-group:focus-within .ah-form-label:not(.is-invalid) {
    @apply text-fullblack;
  }

  .ah-form-control {
    @apply bg-white border border-gray-50 px-4 rounded appearance-none text-base text-softblack block font-normal w-full h-14;
  }

  .ah-form-control:focus {
    @apply border-fullblack outline-none;
  }

  .ah-form-control[disabled] {
    @apply bg-gray-10 border-gray-10 text-gray-90 pointer-events-none;
  }

  .ah-form-control.is-invalid {
    @apply text-red-100 border-red-100;
  }

  .ah-form-control::placeholder {
    @apply text-gray-50;
  }

  .ah-form-control[type='number'] {
    appearance: textfield;
  }

  textarea.ah-form-control {
    @apply py-2;
  }

  .ah-form-control[type='number']::-webkit-outer-spin-button,
  .ah-form-control[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  .ah-form-control + .ah-form-help,
  .ah-form-control + .ah-form-feedback,
  .ah-form-input-group-inner + .ah-form-help,
  .ah-form-input-group-inner + .ah-form-feedback,
  .ah-form-check + .ah-form-help,
  .ah-form-check + .ah-form-feedback {
    @apply mt-1;
  }

  .ah-form.ah-form-compact .ah-form-control:not(.ah-form-textarea) {
    @apply h-8;
  }

  .ah-form-label {
    @apply inline-block text-sm text-gray-90 mb-1 font-normal;
  }

  label[type='button'],
  label[type='submit'] {
    -webkit-appearance: none;
  }

  .ah-form-label.is-invalid {
    @apply text-red-100;
  }

  .ah-form-group.group-horizontal .ah-form-label {
    @apply mb-0 text-right;
  }

  .ah-form-help {
    @apply text-gray-90 font-normal text-xs px-2;
  }

  .ah-form-help + .ah-form-feedback {
    @apply mt-1;
  }

  .ah-form-feedback.is-invalid {
    @apply text-red-100 font-normal text-xs px-2;
  }

  .ah-form-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-position: right 1rem center;
    background-repeat: no-repeat;
    background-size: 1rem 0.75rem;
  }

  .ah-form-textarea {
    height: fit-content;
    min-height: 100px;
  }

  .ah-form-input-group {
    @apply flex w-full;
  }

  .ah-form-input-group > :first-child,
  .ah-form-input-group > :last-child {
    @apply rounded;
  }

  .ah-form-input-group > .ah-form-input-group-inner {
    @apply relative w-full z-0;
  }

  .ah-form-input-group > .ah-form-input-group-inner.prepend .ah-form-control {
    @apply pl-12;
  }

  .ah-form-input-group > .ah-form-input-group-inner.append .ah-form-control {
    @apply pr-12;
  }

  .ah-form-input-group > .ah-form-input-group-inner .ah-input-group-btn {
    @apply absolute top-0 bottom-0 w-5 h-5 my-auto z-10;
  }

  .ah-form-input-group .ah-form-input-group-inner.prepend .overlay-left {
    @apply left-4;
  }

  .ah-form-input-group .ah-form-input-group-inner.append .overlay-right {
    @apply right-4;
  }

  .ah-form-input-group svg {
    @apply pointer-events-none w-full h-full fill-current;
  }

  .ah-form-input-group .ah-form-control:focus {
    @apply relative;
  }

  .ah-form-input-group > .ah-input-group-text {
    @apply flex items-center text-center bg-gray-10 px-4 text-base border border-gray-50;
  }

  .ah-form-input-group > .ah-form-input-group-inner .ah-input-group-text {
    @apply absolute top-0 bottom-0 text-sm w-5 h-5 my-auto text-gray-50 z-10;
  }

  .ah-form-input-group.prepend-controls > .ah-form-input-group-inner .ah-form-control,
  .ah-form-input-group > :not(:first-child) {
    @apply rounded-tl-none rounded-bl-none;
  }

  .ah-form-input-group > :not(:last-child) {
    @apply rounded-tr-none rounded-br-none;
  }

  .ah-form-input-group.append-controls > .ah-form-input-group-inner .ah-form-control,
  .ah-form-input-group > :not(:last-child) {
    @apply rounded-tr-none rounded-br-none;
  }

  .ah-form-input-group > :last-child {
    @apply rounded-tl-none rounded-bl-none;
  }

  .ah-form-input-group > :not(:first-child) {
    @apply -ml-px;
  }

  .ah-form.ah-form-compact .ah-form-input-group select {
    background-position-x: right 0.75rem;
    @apply px-3;
  }

  .ah-form.ah-form-compact .ah-form-input-group > .ah-form-input-group-inner.prepend .ah-form-control {
    @apply pl-10;
  }

  .ah-form.ah-form-compact .ah-form-input-group > .ah-form-input-group-inner.append .ah-form-control {
    @apply pr-10;
  }

  .ah-form.ah-form-compact .ah-form-input-group .ah-form-input-group-inner.prepend .overlay-left {
    @apply left-3;
  }

  .ah-form.ah-form-compact .ah-form-input-group .ah-form-input-group-inner.append .overlay-right {
    @apply right-3;
  }

  .ah-btn {
    @apply text-base leading-5 font-semibold px-8 py-2 text-softblack rounded-full h-14 border-2 inline-flex items-center justify-center relative;
  }

  .ah-btn:hover {
    @apply bg-gray-10 border-gray-10;
  }

  .ah-btn:disabled {
    @apply bg-gray-40 border-gray-40 text-white pointer-events-none;
  }

  .ah-btn-default {
    @apply bg-softblack border-softblack text-white;
  }

  .ah-btn-default:hover {
    @apply bg-gray-80 border-gray-80;
  }

  .ah-btn-stroke {
    @apply bg-white border-softblack text-softblack;
  }

  .ah-btn-stroke:hover {
    @apply bg-gray-10 border-softblack;
  }

  .ah-btn-white {
    @apply bg-white border-white;
  }

  .ah-btn-white:hover {
    @apply bg-gray-10 border-gray-10;
  }

  .ah-btn.has-spinner {
    @apply pointer-events-none;
  }

  .ah-btn.ah-btn-full {
    @apply w-full;
  }

  .ah-btn-sm {
    @apply text-sm px-4 py-2 h-auto border;
  }

  .ah-btn-link {
    @apply bg-transparent border-none underline underline-offset-4 font-normal px-2 h-auto;
  }

  .ah-btn-link:hover {
    @apply bg-transparent no-underline;
  }

  .ah-btn-label {
    @apply bg-transparent border-none font-normal px-2 h-auto;
  }

  .ah-btn-label:hover {
    @apply bg-transparent underline underline-offset-2;
  }

  .ah-btn-label:disabled {
    @apply bg-transparent;
  }

  .ah-spinner {
    @apply border-4 border-r-4 rounded-full border-r-green-70 h-6 w-6 inline-block align-middle animate-spin;
  }

  .ah-btn-group {
    @apply inline-flex relative align-middle;
  }

  .ah-btn-group input[type='checkbox'],
  .ah-btn-group input[type='radio'] {
    @apply appearance-none outline-none;
  }

  .ah-btn-group input[type='checkbox']:focus + .ah-btn,
  .ah-btn-group input[type='radio']:focus + .ah-btn {
    @apply border-black;
    z-index: 1;
  }

  .ah-btn-group .ah-btn {
    @apply flex-auto rounded font-normal cursor-pointer;
  }

  .ah-btn-group .ah-btn:not(:last-child) {
    @apply rounded-tr-none rounded-br-none;
  }

  .ah-btn-group .ah-btn:not(.ah-btn-toggle):nth-child(n + 2),
  .ah-btn-group .ah-btn-toggle:nth-child(n + 3) {
    @apply rounded-tl-none rounded-bl-none -ml-px;
  }

  .ah-btn-group input[type='radio']:checked + label,
  .ah-btn-group input[type='checkbox']:checked + label {
    @apply bg-softblack border-softblack text-white;
  }

  .ah-btn-group input:checked + .ah-btn + input + .ah-btn {
    @apply border-l-softblack;
  }

  .ah-btn-icon {
    @apply bg-transparent border-none inline-block align-middle;
    height: 1.25rem;
    width: 1.25rem;
  }

  .ah-btn-icon:hover {
    @apply bg-gray-10;
  }

  .ah-btn-icon > svg {
    @apply h-full w-full fill-current;
  }

  .ah-combobox {
    @apply relative;
  }

  .ah-combobox-options {
    @apply shadow-md max-h-[300px] overflow-y-auto scrollbar-hide;
  }

  .ah-form-check {
    min-height: 1.25rem;
    @apply pl-7;
  }

  .ah-form-check.strikeout-label label {
    @apply line-through;
  }

  .ah-form-check:hover label,
  .ah-form-check:hover input[type='checkbox'] {
    @apply cursor-pointer underline underline-offset-2 border-green-70;
  }

  .ah-form-check input {
    @apply -ml-7 mt-0.5 float-left;
  }

  .ah-form-check input + label {
    @apply text-base inline-block;
  }

  .ah-form-check input[type='checkbox'] {
    @apply w-5 h-5 rounded border-[0.8px] border-gray-50 appearance-none;
  }

  .ah-form-check input[type='checkbox']:hover {
    @apply border-green-80;
  }

  .ah-form-check input[type='checkbox']:disabled {
    @apply border-gray-40;
  }

  .ah-form-check input[type='checkbox']:checked {
    @apply bg-green-70 border-green-80 bg-no-repeat bg-center;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDUuMjg1NzFMNS44ODg4OSAxMUwxMyAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K');
    background-size: 75% 62.5%;
  }

  .ah-form-check input[type='checkbox']:checked:hover {
    @apply bg-green-50 border-green-50;
  }

  .ah-form-check input[type='checkbox']:checked:disabled {
    @apply bg-gray-30 border-gray-30;
  }

  .ah-form-check ~ .ah-form-help,
  .ah-form-check ~ .ah-form-feedback {
    @apply pl-7;
  }

  .ah-form-check.strikeout-label {
    @apply line-through;
  }

  .ah-form-nav-vertical {
    @apply fixed top-0 bottom-0 p-6 bg-gray-5 w-64 z-50 border-r border-r-gray-30;
  }

  .ah-form-nav-vertical > button {
    @apply block px-4 py-3 text-base w-full text-left rounded text-fullblack font-semibold;
  }

  .ah-form-nav-vertical > button:not(.selected):hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .ah-form-nav-vertical > button + button {
    @apply mt-2;
  }

  .ah-form-nav-vertical > .selected {
    @apply bg-green-70 text-white outline-none;
  }

  .ah-form-content {
    @apply p-8 ml-64 pb-28;
  }

  .ah-form-actions {
    @apply bg-white fixed p-4 bottom-0 left-0 right-0 text-right border-t-2 border-gray-30 z-40;
  }

  .ah-tabs {
    @apply border-b-2 border-gray-30 mb-5;
  }

  .ah-tabs > button {
    @apply font-semibold px-3 py-2;
  }

  .ah-tabs > button + button {
    @apply ml-2;
  }

  .ah-tabs > .selected {
    @apply text-green-70 border-b-4 border-b-green-70 -mb-0.5;
  }

  .ah-datepicker.ah-datepicker-floating .ah-datepicker-calendar {
    @apply relative;
  }

  .ah-datepicker.ah-datepicker-floating .ah-datepicker-calendar > div {
    @apply absolute top-1 w-72;
  }

  .ah-admin-header {
    @apply p-4 mb-4 text-center inline-block;
    line-height: 0;
  }

  .ah-form-check a {
    @apply text-softblack underline;
  }

  .ah-event-table-header {
    @apply py-4 mb-3 bg-green-5;
  }

  .ah-event-table-header > :first-child {
    @apply pl-4;
  }

  .ah-event-table-header > :last-child {
    @apply pr-4;
  }

  .ah-event-table-row {
    @apply py-3 text-sm;
  }

  .ah-event-table-row.open {
    @apply pt-1 md:pt-0;
  }

  .ah-event-table-row a {
    @apply inline-block mb-4;
  }

  .ah-badge {
    @apply rounded-full px-3 py-1 text-sm inline-block text-gray-90 text-center;
  }

  .ah-badge-success {
    @apply border-green-5 bg-green-5;
  }

  .ah-badge-warning {
    @apply border-purple-10 bg-purple-10;
  }

  .ah-badge-danger {
    @apply border-red-20 bg-red-20;
  }

  .ah-media-gallery {
    @apply bg-gray-20;
  }

  .ah-media-gallery .image-gallery-slide-wrapper {
    @apply h-[270px] sm:h-[310px] md:h-[350px] lg:h-[450px];
  }

  .ah-media-gallery .image-gallery-swipe,
  .ah-media-gallery .image-gallery-slides,
  .ah-media-gallery .image-gallery-slide {
    @apply h-full;
  }

  .ah-media-gallery-nav {
    @apply w-14 h-14 p-2 bg-white absolute z-10 top-0 bottom-0 my-auto;
  }

  .ah-media-gallery-nav:hover {
    @apply cursor-pointer;
  }

  .ah-media-gallery-nav.nav-left {
    @apply left-0 -ml-7;
  }

  .ah-media-gallery-nav.nav-right {
    @apply right-0 -mr-7;
  }

  .image-gallery-content.fullscreen .ah-media-gallery-nav.nav-left {
    @apply left-4 ml-0;
  }

  .image-gallery-content.fullscreen .ah-media-gallery-nav.nav-right {
    @apply right-4 mr-0;
  }

  .ah-media-gallery .image-gallery-bullets {
    @apply -bottom-8;
  }

  .image-gallery-content.fullscreen .image-gallery-bullets {
    @apply bottom-6;
  }

  .ah-media-gallery .image-gallery-bullets .image-gallery-bullet {
    @apply border-green-70 shadow-none;
  }

  .ah-media-gallery .image-gallery-bullets .image-gallery-bullet.active {
    @apply bg-green-70 border-green-70;
  }

  .ah-media-gallery.image-gallery.fullscreen-modal {
    @apply bg-gray-10 z-50;
  }

  .ah-media-gallery .image-gallery-content.fullscreen {
    @apply bg-transparent;
  }

  .ah-media-gallery .fullscreen .image-gallery-slide-wrapper {
    height: calc(100vh - 4rem);
    max-height: -webkit-fill-available;
    @apply pt-16;
  }

  .ah-media-gallery-desktop .fullscreen .image-gallery-slide img {
    width: fit-content !important;
    height: fit-content !important;
    min-width: fit-content !important;
    max-width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
  }

  .ah-media-gallery-mobile .fullscreen .image-gallery-slide img {
    width: fit-content !important;
    height: fit-content !important;
    min-width: fit-content !important;
    min-height: auto !important;
  }

  .ah-media-gallery-mobile .fullscreen .image-gallery-slide .ah-media-gallery-video-wrapper img {
    min-height: 100% !important;
    min-width: 100% !important;
  }

  .ah-media-gallery .ah-gallery-exit-fullscreen {
    @apply absolute right-3 top-3 w-10 h-10 flex bg-white justify-center items-center cursor-pointer;
  }

  .fullscreen .ah-media-gallery-video-wrapper {
    @apply w-[85%] h-full mx-auto;
  }

  .ah-media-gallery-video-wrapper {
    @apply w-full h-full;
  }

  .ah-media-gallery-video-wrapper button {
    @apply w-full h-full relative;
  }

  .ah-media-gallery-video-wrapper button > .play-button {
    @apply flex bg-white items-center justify-center rounded-full w-12 h-12 absolute top-0 bottom-0 left-0 right-0 m-auto z-10;
  }

  .ah-media-gallery-video-wrapper .play-button > svg {
    @apply fill-softblack;
  }

  .ah-profile-avatar {
    @apply flex items-center justify-center w-28 h-28 rounded-full block relative mx-auto overflow-hidden bg-gray-5;
  }

  .ah-member-profile .ah-profile-socials {
    @apply bg-transparent py-0;
  }

  .ah-member-profile .ah-profile-socials > div {
    @apply gap-0;
  }

  .ah-member-profile .ah-profile-socials > div > div {
    @apply w-full md:w-auto;
  }

  .ah-member-profile .ah-profile-socials > div > div + div {
    @apply mt-4 md:mt-0 md:ml-8;
  }

  .ah-member-profile .ah-profile-socials .social-link > div {
    @apply flex-row h-auto py-0 md:w-auto;
  }

  .ah-member-profile .ah-profile-socials .social-icon {
    @apply mb-0 h-auto w-auto;
  }

  .ah-member-profile .ah-profile-socials svg {
    @apply h-6 w-6 mr-2;
  }

  .description p {
    display: inline;
  }

  #privacy-policy h1,
  #cookie-policy h1 {
    @apply text-3xl font-bold;
  }

  #privacy-policy h2,
  #cookie-policy h2 {
    @apply text-2xl font-semibold mt-8;
  }

  #privacy-policy h3,
  #cookie-policy h3 {
    @apply text-base font-semibold mt-4;
  }

  #privacy-policy h3 p,
  #cookie-policy h3 p {
    @apply text-base font-normal;
  }

  #privacy-policy a,
  #cookie-policy a {
    @apply text-softblack underline;
  }

  #cookie-policy table {
    @apply w-full flex overflow-x-scroll flex-row sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5 text-left mb-16 sm:px-0;
  }

  #cookie-policy table tr th {
    @apply bg-green-5 text-left text-base py-4 font-normal mx-2 px-4;
  }

  #cookie-policy table tr #purpose {
    @apply min-w-[250px];
  }

  #cookie-policy table tr #name {
    @apply w-[100px];
  }

  #cookie-policy table tr td {
    @apply text-left align-text-top text-base py-4 font-normal mr-2 px-4 text-sm;
  }

  .article p {
    @apply mb-4;
  }

  .article pre {
    @apply whitespace-pre-line;
  }

  .ah-max-content-width {
    @apply max-w-[860px] 4xl:max-w-[861px];
  }

  .ah-article img {
    @apply mx-auto my-6;
  }

  .ah-article a {
    @apply text-softblack underline;
  }

  .ah-article ul {
    @apply px-4 mb-4;
  }

  .ah-article h4 {
    @apply mb-2;
  }

  .ah-article h2 {
    @apply mt-6 text-2xl font-bold;
  }

  .ah-article p {
    @apply mt-4 mb-2 text-left;
  }

  .ah-article table tr td {
    @apply px-4;
  }

  .ah-hero-image {
    @apply relative w-full h-full;
    padding-bottom: 100vw;
  }

  .ah-hero-image img {
    object-position: 44.5%;
  }

  .red-link a {
    @apply text-red-100 underline;
  }

  .ah-table thead {
    @apply bg-green-5 text-left;
  }

  .ah-table thead > tr > th {
    @apply font-normal py-4;
  }

  .ah-table thead > tr > th:first-child {
    @apply pl-4;
  }

  .ah-table thead > tr > th:last-child {
    @apply pr-4;
  }

  .ah-table tbody {
    @apply text-sm;
  }

  .ah-table tbody > tr > td {
    @apply py-3;
  }

  .ah-table tbody > tr:first-child > td {
    @apply pt-6;
  }

  @media screen and (min-width: 768px) {
    .ah-hero-image {
      padding-bottom: 43.3845%;
    }

    .ah-hero-image img {
      object-position: 0;
    }
  }

  #featured-event-skeleton,
  #filter-items-skeleton {
    animation: flash 0.9s ease infinite;
  }

  #featured-event-skeleton .skeleton-text-container {
    @apply py-[5px];
  }

  #featured-event-skeleton .skeleton-text {
    @apply h-[10px] bg-gray-20 rounded;
  }

  .skeleton-icon {
    @apply h-4 w-4 rounded-full bg-gray-20;
  }

  .reviews-score-group > div {
    @apply w-full;
  }

  .reviews-score {
    @apply w-6 h-6 inline-block text-center;
  }

  .reviews-score-max {
    width: calc(100% - 24px - 10px);
    max-width: 240px;
    @apply inline-block h-2 ml-2 rounded bg-gray-20 relative;
  }

  .reviews-score-progress {
    @apply absolute bg-[#FFC700] w-full left-0 h-2 rounded;
  }

  .inline-text {
    @apply inline-block;
  }

  .ah-newsletter-container {
    @apply max-w-[1160px] py-8 px-10 border border-gray-30 mx-auto;
  }

  .ah-newsletter-conditions a,
  .ah-newsletter-sign-in a {
    @apply underline hover:cursor-pointer;
  }
}
